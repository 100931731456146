import { AppProps } from "next/app";
import React, { useEffect } from "react";
import Head from "next/head";

import { museTrack, getLinkTrackingProps, trackHeaderNavLinks } from "utils/tracking";

import "styles/globals.scss";
import "styles/jwplayer.scss";

function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        // eslint-disable-next-line quotes
        const header: HTMLElement = document.querySelector(`header[class^="Header_header"]`);
        header?.addEventListener("click", trackHeaderNavLinks, false);
        const tracker = (e: MouseEvent | React.MouseEvent<HTMLElement, HTMLAnchorElement>) => getLinkTrackingProps(e, museTrack);
        document.addEventListener("click", tracker, false);
        document.addEventListener("contextmenu", tracker, false);
        return () => {
            document.removeEventListener("click", tracker);
            document.removeEventListener("contextmenu", tracker);
        };
    }, []);

    return (
        <>
            <Head>
                {/* charset|viewport|http-equiv */}
                <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Component {...pageProps} />
        </>
    );
}

export default MyApp;
